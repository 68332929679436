import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH,
  MAX_TABLET_WIDTH,
  MIN_2K,
  MIN_4K,
  MAX_TABLET_WIDE_WIDTH,
  MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';
import Intersection from '../Intersection/Intersection';

export const Container = styled(Intersection)`
  width: 100%;
  position: relative;
  z-index: 0;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    height: 60vh;
    margin-top: ${MOBILE_TOP_MENU_HEIGHT}px;
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  z-index: -1;
  padding-top: 140px;
  padding-bottom: 40px;

  @media (max-width: ${MAX_TABLET_WIDE_WIDTH}px) {
    z-index: 10;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 100px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 20px;
    padding-bottom: 0;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 175px;
    padding-bottom: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 320px;
    padding-bottom: 240px;
  }
`;
