import styled from '@emotion/styled';

import {
  mobWidth,
} from 'constants/sizes';

const ModernLayout = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(16, minmax(0, 1fr));

  padding-left: calc(var(--unit) * 8);
  padding-right: calc(var(--unit) * 8);
  column-gap: calc(var(--unit) * 2);

  @media (max-width: ${mobWidth}) {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    max-width: 100vw;
  }
`;

export default ModernLayout;
