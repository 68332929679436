import styled from '@emotion/styled';
import { Intersection } from 'UI';

export const Fixed = styled.div`
  position: sticky;
  top: 0;
  height: 0;
  z-index: 0;

  > * {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > *:nth-of-type(1) {
    z-index: 1;
    clip-path: inset(-1px 0px var(--relative) 0px);
  }
`;

export const Wrappper = styled(Intersection)`
  position: relative;
`;
