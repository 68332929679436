import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ButtonStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  padding: 0 2em;

  transition: color 0.2s ease, background-color 0.2s ease;

  background-color: #eaeaea;
  color: #000;
  cursor: pointer;

  :hover {
    color: #fff;
    background-color: #000;
    font-weight: 600;
  }
`;

export const UILink = styled.a`
  ${ButtonStyle}
`;

export const UIButton = styled.button`
  ${ButtonStyle}
`;
