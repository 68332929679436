import React, { FC } from 'react';

import { IIcon } from './interfaces';

const CheckIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M16 1.42736L6.40543 13.3333L0 8.48975L1.50276 6.65736L5.9836 10.0456L14.079 0L16 1.42736Z" />
  </svg>
);

export default CheckIcon;
