import React, { FC } from 'react';

import { IIcon } from './interfaces';

const InstagramIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m 12,1 c 0,0.55228 -0.4477,1 -1,1 -0.5523,0 -1,-0.44772 -1,-1 0,-0.55228 0.4477,-1 1,-1 0.5523,0 1,0.44772 1,1 z M 10,6 C 10,8.2091 8.2091,10 6,10 3.79086,10 2,8.2091 2,6 2,3.79086 3.79086,2 6,2 c 2.2091,0 4,1.79086 4,4 z" />
  </svg>
);

export default InstagramIcon;
