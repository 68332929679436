import styled from '@emotion/styled';

import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';
import MiddleText from 'UI/MiddleText/MiddleText';

export const Preview = styled.div`
  grid-area: p;
`;

export const Icon = styled.div`
  grid-area: i;
`;

export const Title = styled(MiddleText)`
  grid-area: t;
`;

export const Date = styled.small`
  grid-area: d;
`;

export const Wrapper = styled.a`
  display: grid;
  grid-auto-flow: row;
  row-gap: 0.8rem;
  align-content: start;
  grid-template-areas:
    'p'
    'i'
    't'
    'd';

  :hover {
    ${Title} {
      ${ArrowLinkStyles}
    }
  }
`;
