import React, { FC } from 'react';

import { IIcon } from './interfaces';

const LinkIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M4.69844 16C3.76923 16 2.86089 15.7244 2.08826 15.2082C1.31564 14.692 0.71343 13.9583 0.357778 13.0998C0.00212557 12.2414 -0.0909979 11.2967 0.0901818 10.3854C0.271361 9.47398 0.718708 8.6368 1.37566 7.97965L3.36876 5.9856L4.6975 7.31434L2.70439 9.30744C2.17567 9.83617 1.87863 10.5533 1.87863 11.301C1.87863 12.0487 2.17567 12.7659 2.70439 13.2946C3.23312 13.8233 3.95023 14.1204 4.69797 14.1204C5.4457 14.1204 6.16281 13.8233 6.69154 13.2946L8.6837 11.3015L10.0134 12.6312L8.02027 14.6243C7.58485 15.0618 7.06699 15.4087 6.49664 15.6449C5.9263 15.881 5.31479 16.0017 4.6975 16H4.69844ZM5.3628 11.9659L4.03407 10.6371L10.6777 3.9925L12.0074 5.32124L5.36374 11.9649L5.3628 11.9659ZM12.6727 9.97275L11.3431 8.64401L13.3352 6.65091C13.6045 6.39092 13.8194 6.0799 13.9672 5.73599C14.115 5.39209 14.1929 5.02218 14.1962 4.64787C14.1996 4.27355 14.1283 3.90232 13.9866 3.55583C13.845 3.20934 13.6357 2.89453 13.3711 2.62977C13.1065 2.36502 12.7918 2.15562 12.4453 2.01379C12.0989 1.87196 11.7277 1.80055 11.3534 1.80371C10.9791 1.80687 10.6091 1.88456 10.2652 2.03222C9.92119 2.17988 9.61007 2.39458 9.34995 2.66377L7.35591 4.65687L6.02717 3.32813L8.02027 1.33409C8.90519 0.471451 10.0943 -0.0078213 11.3301 9.65674e-05C12.5659 0.00801443 13.7488 0.502485 14.6226 1.37639C15.4964 2.2503 15.9907 3.43328 15.9984 4.66906C16.0062 5.90484 15.5267 7.09392 14.6639 7.97871L12.6718 9.97181V9.97275H12.6727Z" />
  </svg>
);

export default LinkIcon;
