import React, {
  useRef, useEffect, forwardRef, useState, HTMLAttributes,
} from 'react';

import { IMediaVideo } from 'interfaces/media';

import throttle from 'utils/throttle';

import {
  VideoElement, Wrapper, PlayButtonWrapper, PlayButton, PlayWrapper,
} from './GatsbyVideo.styled';

interface IIntersectionOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number[] | number;
}

interface IGatsbyVideo extends HTMLAttributes<HTMLVideoElement> {
  media: IMediaVideo;
  className?: string;
  preload?: string;
  loop?: boolean;
  playsInline?: boolean;
  muted?: boolean;
  controls?: boolean;
  playButton?: boolean;
  poster?: string;

  playOnVisible?: boolean;
  intersectionOptions?: IIntersectionOptions;
}

const GatsbyVideo = forwardRef<HTMLVideoElement, IGatsbyVideo>(
  (
    {
      media,
      className = '',
      preload = 'auto',
      loop = false,
      playsInline = true,
      muted = true,
      playOnVisible = true,
      intersectionOptions = null,
      controls = false,
      playButton = false,
      poster,
      ...props
    },
    ref,
  ) => {
    if (!media?.localFile) return null;

    const [active, setActive] = useState<boolean>(false);
    const [hasControls, setControls] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const src = media.localFile.FHD?.path || '';
    const srcSet = media.localFile;

    let realWindowSize = 0;
    const voidFunction = () => {};

    const setVideoSize = async () => {
      if (!videoRef.current || (realWindowSize === window.innerWidth && videoRef.current.src)) {
        return;
      }

      realWindowSize = window.innerWidth;

      let activeSrc = '';

      if (realWindowSize > 2048) {
        activeSrc = src;
      } else if (realWindowSize > 1400) {
        activeSrc = srcSet.FHD.path;
      } else if (realWindowSize > 900) {
        activeSrc = srcSet.HD.path;
      } else {
        activeSrc = srcSet.qHD.path;
      }

      if (!videoRef.current?.src.includes(activeSrc)) {
        const { paused } = videoRef.current;
        videoRef.current.src = activeSrc;

        if (!paused) {
          try {
            await videoRef.current?.play();
          } catch (error) {
            videoRef.current?.pause();
          }
        }
      }
    };

    const pauseLastFrame = () => {
      if (!videoRef.current) return;

      videoRef.current.addEventListener('ended', () => {
        videoRef.current?.pause();
        videoRef.current.currentTime = videoRef.current.duration;
      }, false);
    };

    const playVideo = async (isIntersecting: boolean) => {
      if (!videoRef.current) return;

      if (isIntersecting) {
        try {
          await videoRef.current?.play();
          setActive(true);
        } catch (error) {
          videoRef.current?.pause();
          setActive(false);
        }
      } else {
        videoRef.current?.pause();
        setActive(false);
      }
    };

    const handlerObserver = (entries: IntersectionObserverEntry[]) => {
      const { isIntersecting } = entries[0];

      if (srcSet) {
        setVideoSize().then(voidFunction, voidFunction);
      }
      // eslint-disable-next-line no-void
      void playVideo(isIntersecting);
    };

    const handleResize = () => {
      throttle(() => {
        setVideoSize().then(voidFunction, voidFunction);
      }, 400)();
    };

    const handlePlayVideo = async () => {
      await playVideo(true);
      setControls(true);
    };

    const handleEnd = () => {
      setActive(false);
      if (!controls) {
        setControls(false);
      }
    };

    useEffect(() => {
      if (!loop) {
        pauseLastFrame();
      }

      if (playOnVisible) {
        const options = intersectionOptions || {
          threshold: 0.5,
          rootMargin: '0px 0px 0px',
        };

        const observer = new IntersectionObserver(handlerObserver, options);

        observer.observe(videoRef.current);
      }

      if (srcSet !== null) {
        window.addEventListener('resize', handleResize, true);
        setVideoSize().then(voidFunction, voidFunction);
      }

      setControls(controls);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <Wrapper className={className}>
        {playButton && (
          <PlayWrapper
            onClick={handlePlayVideo}
            className={active ? 'hidden' : ''}
          >
            <PlayButtonWrapper>
              <PlayButton />
            </PlayButtonWrapper>
          </PlayWrapper>
        )}
        <VideoElement
          preload={preload}
          loop={loop}
          playsInline={playsInline}
          muted={muted}
          poster={
            poster || media.localFile.videoScreenshots?.[0]?.publicURL
          }
          src={src}
          type={media.mime}
          controls={hasControls}
          onEnded={handleEnd}
          ref={(node) => {
            videoRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              // eslint-disable-next-line no-param-reassign
              ref.current = node;
            }
          }}
          {...props}
        />
      </Wrapper>
    );
  },
);

export default GatsbyVideo;
